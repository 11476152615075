'use strict';

const MIN_SUGGESTION_SIZE = 3;
const INPUT_DELTA = 100;
const ESCAPE_KEY = 27;
const HTTP_STATUS_CODE_OK = 200;
const SUGGEST_PATH = '/search/suggest';
const eventsService = require('./events');
const debounce = require('./debounce.js');

module.exports = function search() {
  const form = document.getElementById('header-search-bar');
  const suggestionsContainer = document.getElementById('header-search-suggestions-results');
  const input = form.querySelector('input');
  let hasContent = false;

  form.appendChild(suggestionsContainer);

  input.oninput = debounce(processNewInputEvent, INPUT_DELTA);

  input.onblur = hideSuggestionsContainer;

  input.onfocus = showSuggestionsContainer;

  input.onkeyup = function(event) {
    if (event.keyCode === ESCAPE_KEY) {
      hideSuggestionsContainer();
      input.blur();
    }
  };

  function processNewInputEvent(event) {
    const text = event.target.value;

    if (text.length >= MIN_SUGGESTION_SIZE) {
      const request = new window.XMLHttpRequest();
      request.open('GET', `${SUGGEST_PATH}?text=${text}`);

      request.onload = handleSearchSuggestions;

      request.send();
    } else {
      clearSearchSuggestions();
    }
  }

  function handleSearchSuggestions(event) {
    if (event.target.status === HTTP_STATUS_CODE_OK) {

      suggestionsContainer.innerHTML = event.target.responseText;

      // prevents the mouse click from trigger the input onblur event
      Array.prototype.forEach.call(
        suggestionsContainer.querySelectorAll('a, #suggestion-did-you-mean'),
        link => { link.onmousedown = linkEvent => linkEvent.preventDefault(); }
      );

      const didYouMean = suggestionsContainer.querySelector('#suggestion-did-you-mean');

      if (didYouMean) {
        didYouMean.addEventListener('click', () => {
          input.value = didYouMean.textContent;
          form.submit();
        });
      }

      hasContent = true;
      showSuggestionsContainer();
    } else {
      clearSearchSuggestions();
    }
  }

  function clearSearchSuggestions() {
    hideSuggestionsContainer();
    suggestionsContainer.innerHTML = '';
    hasContent = false;
  }

  function showSuggestionsContainer() {
    if (hasContent) {
      suggestionsContainer.classList.add('active');

      //monitor clicks on suggestions
      Array.prototype.forEach.call(
        document.querySelectorAll('#header-search-suggestions-results *[data-event-action]'),
        element => { element.addEventListener('click', eventsService.onTrackedAction); }
      );
    }
  }

  function hideSuggestionsContainer() {
    suggestionsContainer.classList.remove('active');
  }
};
