'use strict';

require('../polyfills/element-matches.js');

module.exports = {
  trackEvents,
  trackEventsInContainer,
  onTrackedAction,
  track,
  getDataEvent,
  getPageName,
};

const SELECTOR_FOLD = '.foldable-toggler';
const SELECTOR_ANALYTICS_FORM = 'form.search[data-event-action]';
const SELECTOR_ANALYTICS = '[data-event-action]:not(form)';

function trackEvents() {
  Array.prototype.forEach.call(
    document.querySelectorAll(SELECTOR_FOLD),
    foldableElement => { foldableElement.addEventListener('toggle', onFoldAction); }
  );

  Array.prototype.forEach.call(
    document.querySelectorAll(SELECTOR_ANALYTICS_FORM),
    formElement => { formElement.addEventListener('submit', onSearchAction); }
  );

  Array.prototype.forEach.call(
    document.querySelectorAll(SELECTOR_ANALYTICS),
    element => { element.addEventListener('click', onTrackedAction); }
  );
}

/**
 * Like `trackEvents()`, but uses event delegation to track actions in a live container (which deletes or creates new children).
 * @param {HTMLElement} container
 */
function trackEventsInContainer(container){
  container.addEventListener('toggle', event => {
    if(event.target.matches(SELECTOR_FOLD)){
      trakFoldAction(event.target, event.detail);
    }
  });

  container.addEventListener('click', event => {
    if(event.target.matches(SELECTOR_ANALYTICS)){
      trackAction(event.target);
    }
  });

  container.addEventListener('submit', event => {
    if(event.target.matches(SELECTOR_ANALYTICS_FORM)){
      trackSearchAction(event.target);
    }
  });
}

/** @param {CustomEvent<boolean>} event */
function onFoldAction(event){
  trakFoldAction(event.currentTarget, event.detail);
}

/**
 * @param {HTMLElement} element
 * @param {boolean} isFolded toggler new state
 */
function trakFoldAction(element, isFolded){
  /* We want to track the click on the toggler before it changes its state.
   * But at this point, it has changed already.
   * So we get the data corresponding to its previous state, when the click occurred.
   */
  const data = getFoldDataEvent(element, !isFolded);
  if(data && data.action){
    track(data);
  }
}

/**
 * @param {HTMLElement} element
 * @param {boolean} isFolded toggler new state
 */
function getFoldDataEvent(element, isFolded){
  if(element){
    const state = isFolded ? 'folded' : 'unfolded';
    return getDataEvent(element, {
      eventActionClass: `data-event-action-${ state }`,
      eventLabelClass: `data-event-label-${ state }`,
    });
  }
}

/**
 * Track submit form with page as category and action as data-event-action on form element
 * The form must contain an input[type="search"] to get a label tracking
 * @param {Event} event
 */
function onSearchAction(event) {
  trackSearchAction(event.currentTarget);
}

/** @param {HTMLElement} element */
function trackSearchAction(element){
  const searchInputElement = element.querySelector('input[type="search"]');
  track({
    category: getPageName(),
    action: element.getAttribute('data-event-action'),
    label: searchInputElement && searchInputElement.value || '',
  });
}

/** @param {Event} event */
function onTrackedAction(event){
  trackAction(event.currentTarget);
}

/** @param {HTMLElement} element */
function trackAction(element){
  const data = getDataEvent(element);
  if(data && data.action){
    track(data);
  }
}

/**
 * @param {Element} element
 * @param {{ eventActionClass?: string, eventLabelClass?: string }} [options]
 * @returns {{ category: string, action: string|null, label: string } | undefined}
 */
function getDataEvent(element, { eventActionClass='data-event-action', eventLabelClass='data-event-label'} = {}){
  if(element){
    return {
      category: getPageName(),
      action: element.getAttribute(eventActionClass),
      label: element.getAttribute(eventLabelClass) || element.textContent.trim(),
    };
  }
}

function track({ category, action, label }) {
  const { gtag } = window;

  if(gtag){
    gtag('event', action, {
      event_category: category,
      event_label: label,
    });
  }
}

/**
 * @param {Window} context
 * @returns
 */
function getPageName(context = window){
  const { urlPrefix } = context;
  let pageName = context.document.location.pathname;
  if(pageName === "/") {
    pageName = "Home Page";
  } else if(pageName.indexOf(`${urlPrefix}/sector/`) === 0) {
    pageName = "Sector Page";
  } else if (pageName.indexOf(`${urlPrefix}/ranking/`) === 0) {
    pageName = "Ranking Page";
  } else if (pageName.indexOf('/search') === 0) {
    pageName = 'Search Page';
  } else if (pageName.indexOf(`${urlPrefix}/timeseries/`) === 0) {
    pageName = "Data Page";
  }
  return pageName;
}
