'use strict';

/**
 * @param {Element} tabset
 * @param {Object} [options]
 * @param {Boolean} [options.hideable] when **false**, there is always 1 displayed tab. Otherwise, all tabs can be hidden at the same time.
 * @returns {void}
 */
module.exports = function initializeTabs(tabset, { hideable } = {}){
  const CLASS_ACTIVE = 'active';

  Array.prototype.forEach.call(
    tabset.querySelectorAll('.tabs > .tab'),
    tab => tab.addEventListener('click', onTabClick)
  );

  if(hideable){
    document.addEventListener('click', onClickOutside);
  }

  /**
   * Displays the selected tab pane.
   * @this {Element}
   * @returns {void}
   */
  function onTabClick(){
    let tabIndex;

    if(!this.classList.contains(CLASS_ACTIVE)){
      Array.prototype.forEach.call(
        tabset.querySelectorAll('.tabs > .tab'),
        (tab, i) => {
          if(tab === this){
            tab.classList.add(CLASS_ACTIVE);
            tabIndex = i;
          }else{
            tab.classList.remove(CLASS_ACTIVE);
          }
        }
      );

      Array.prototype.forEach.call(
        tabset.querySelectorAll('.tab-pane'),
        (pane, i) => i === tabIndex ? pane.classList.add(CLASS_ACTIVE) : pane.classList.remove(CLASS_ACTIVE)
      );
    }else if(hideable){
      hideAll();
    }
  }

  /**
   * Hides all panes if the clicked element is outside the tabset. Otherwise, does nothing.
   * @param {Event} event
   * @returns {void}
   */
  function onClickOutside(event){
    if(!tabset.contains(event.target)){
      hideAll();
    }
  }

  /**
   * Hides all tabs and panes.
   * @returns {void}
   */
  function hideAll(){
    Array.prototype.forEach.call(
      tabset.querySelectorAll('.tabs > .tab.active, .tab-pane.active'),
      pane => pane.classList.remove(CLASS_ACTIVE)
    );
  }
};
