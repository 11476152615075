"use strict";

module.exports = init;

function init() {

  const carets = document.getElementsByClassName('sector-caret');
  const sectorSnippets = document.getElementsByClassName('sector-snippet');
  const sectorNames = document.getElementsByClassName('sector-name');
  const sectorChildren = document.getElementsByClassName('sector-children');

  Array.prototype.forEach.call(
    sectorSnippets,
    element => { element.onclick = () => clickSector(element.dataset.index); }
  );

  /**
   * Hide sector children when clicking outside sector treeview elements
   */
  document.addEventListener('click', event => {
    if (!isClickInsideSectorTreeview(event)) {
      hideAllSectors();
    }
  });

  /**
   * Toggle display of sector children element
   * @param {integer} index
   */
  function clickSector(index) {
    // Use getComputedStyle for checking display
    // Do not use element.style.display, it only check style on tag
    if( window.getComputedStyle(sectorChildren[index]).display === 'block') {
      hideSector(index);
    } else {
      showSector(index);
    }
  }

  function hideSector(index) {
    sectorNames[index].classList.add('sector-name-unselected');
    sectorNames[index].classList.remove('sector-name-selected');
    carets[index].classList.add('caret-down');
    carets[index].classList.remove('caret-up');
    hide(sectorChildren[index]);
  }

  function showSector(index) {
    sectorNames[index].classList.add('sector-name-selected');
    sectorNames[index].classList.remove('sector-name-unselected');
    carets[index].classList.add('caret-up');
    carets[index].classList.remove('caret-down');
    showSectorChildren(index);
  }

  /**
   * Hide all sectors
   */
  function hideAllSectors() {
    Array.prototype.forEach.call(sectorSnippets, element => hideSector(element.dataset.index));
  }

  /**
   * Hide element
   * @param {node} element
   */
  function hide(element) {
    element.style.display = 'none';
  }

  /**
   * Show displayedIndex sector children element and hide all others
   * @param {string|integer} displayedIndex
   */
  function showSectorChildren(displayedIndex) {
    displayedIndex = +displayedIndex;
    for (let i = 0; i < sectorChildren.length; i++) {
      if(i === displayedIndex) {
        sectorChildren[i].style.display = 'block';
      } else {
        hideSector(i);
      }
    }
  }

  /**
   * Tell if the click is inside one the sector treeview elements
   * @param {event} event
   */
  function isClickInsideSectorTreeview(event) {
    return Array.prototype.some.call(sectorSnippets, element => element.contains(event.target));
  }
}
