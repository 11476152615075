'use strict';

/**
 * Initializes the Go Top button.
 * @returns {void}
 */
module.exports = function initializeGotop(){
  const CLASS_ACTIVE = 'gotop-active';
  const SCROLL_LIMIT = 80; // corresponds to the header's height (px)
  const button = document.getElementById('gotop');

  button.onclick = goBackToTop;
  document.addEventListener('scroll', toggleGoTopButton);
  toggleGoTopButton();

  function toggleGoTopButton(){
    if(document.documentElement.scrollTop >= SCROLL_LIMIT){
      button.classList.add(CLASS_ACTIVE);
    }else{
      button.classList.remove(CLASS_ACTIVE);
    }
  }

  /**
   * @param {Event} event
   * @returns {void}
   */
  function goBackToTop(event){
    event.preventDefault();
    document.documentElement.scrollTop = 0;
  }
};
