'use strict';

/**
 * Adds clickable dots to the given carousel.
 * @param {Element} element
 * @param {Carousel.DotsCarouselOptions} [options]
 * @returns {Carousel.bindCarousel}
 */
module.exports = function carouselDots(element, { classNameDotContainer = 'js_dots', classNameSlideContainer = 'js_slides', infinite } = {}){
  /** @type {import('lory.js').LoryStatic} */
  let carousel;
  const CLASS_ACTIVE = 'active';
  const dotContainer = element.querySelector(`.${ classNameDotContainer }`);
  const slideContainer = element.querySelector(`.${ classNameSlideContainer }`);
  const dotCount = slideContainer ? slideContainer.children.length : 0;

  if(dotContainer && dotCount > 1){
    element.addEventListener('before.lory.init', createDots);
    element.addEventListener('after.lory.init', bindDots);
    element.addEventListener('after.lory.slide', selectActiveDot);
    element.addEventListener('on.lory.resize', () => selectDot(0));
  }

  return lory => (carousel = lory);

  /**
   * Creates as many dots as slides in the carousel.
   * @returns {void}
   */
  function createDots(){
    for(let i = 0; i < dotCount; i++){
      dotContainer.appendChild(document.createElement('li'));
    }

    dotContainer.children[0].classList.add(CLASS_ACTIVE);
  }

  /**
   * Binds dots event handlers.
   * @returns {void}
   */
  function bindDots(){
    for(let i = 0; i < dotContainer.children.length; i++){
      dotContainer.children[i].addEventListener('click', onDotClick);
    }
  }

  /**
   * Selects the new active dot and unselect the previous active one.
   * @param {CustomEvent} event
   * @returns {void}
   */
  function selectActiveDot(event){
    selectDot(infinite ? event.detail.currentSlide - 1 : event.detail.currentSlide);
  }

  /**
   * Displays the slide associated to the clicked dot.
   * @param {Event} event
   * @returns {void}
   */
  function onDotClick(event){
    const index = Array.prototype.indexOf.call(dotContainer.children, event.target);
    carousel.slideTo(index);
  }

  /**
   * Selects the dot at the given index and unselect all the others.
   * @param {number} index
   * @returns {void}
   */
  function selectDot(index){
    for(let i = 0; i < dotContainer.children.length; i++){
      dotContainer.children[i].classList.remove(CLASS_ACTIVE);
    }

    dotContainer.children[index].classList.add(CLASS_ACTIVE);
  }
};
