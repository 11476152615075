'use strict';

/**
 * Delays function `func` invocation for `wait` milliseconds, using `setTimeout()`, so the return value will be lost.
 * If the debounced function is invoked multiple times within the `wait` period,
 * only the last invocation will actually call the function `func`, canceling the preceding invocations.
 * For a more advanced implementation, see [lodash.debounce](https://lodash.com/docs/4.17.15#debounce).
 * @param {Function} func
 * @param {number} wait time in milliseconds
 */
module.exports = function debounce(func, wait = 0){
  /** @type {number} */
  let timeoutId;

  /** @this any */
  return function debounced(...args){
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func.apply(this, args), wait);
  };
};
