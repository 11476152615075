'use strict';

/**
 * Animates a carousel.
 * @param {Element} element
 * @param {Carousel.DynamicCarouselOptions} [options]
 * @returns {Carousel.bindCarousel}
 */
module.exports = function carouselAnimation(element, { classNameSlideContainer = 'js_slides', slidePause = 10000 } = {}){
  /** @type {import('lory.js').LoryStatic} */
  let carousel;
  /** @type {number} */
  let timeoutId;
  const slideContainer = element.querySelector(`.${ classNameSlideContainer }`);
  const slideCount = slideContainer ? slideContainer.children.length : 0;

  if(slidePause > 0 && slideCount > 1){
    element.addEventListener('after.lory.init', moveForward);
    element.addEventListener('before.lory.slide', () => clearTimeout(timeoutId));
    element.addEventListener('after.lory.slide', moveForward);
    element.addEventListener('on.lory.resize', resetTimer);
  }

  return lory => (carousel = lory);

  /**
   * Prepares next slide to show.
   * @returns {void}
   */
  function moveForward(){
    const time = Math.abs(+element.querySelector(`.${ classNameSlideContainer } > .active`).getAttribute('data-time'));
    timeoutId = setTimeout(() => carousel.next(), time || slidePause);
  }

  /**
   * Resets timer to the trigger the next slide after the right tiem.
   * @returns {void}
   */
  function resetTimer(){
    clearTimeout(timeoutId);
    moveForward();
  }
};
