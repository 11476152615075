'use strict';

const { lory } = require('lory.js');
const carouselAnimation = require('../utils/carousel-animation');
const carouselDots = require('../utils/carousel-dots.js');

/**
 * Initializes Home Page carousels.
 * @returns {void}
 */
module.exports = () => {
  const options = {
    infinite: 1,
    enableMouseEvents: true,
    slideSpeed: 500,
  };

  const carousels = document.getElementsByClassName('carousel');

  for(let i = 0; i < carousels.length; i++){
    const element = carousels[i];
    const animate = carouselAnimation(element);
    const dots = carouselDots(element, options);
    const carousel = lory(element, options);
    animate(dots(carousel));
  }
};
