'use strict';

const tracking = require('../events.js');

/**
 * @param {string} src
 */
module.exports = function createIframeBanner(src) {
  const localStorageKey = `banner-wrapper-iframe__${ src }`;

  /** @type {HTMLIFrameElement|undefined} */
  let iframe;

  return {
    open,
    close,
  };

  /**
   * @returns {boolean} - `true` if the iframe could be open, `false` otherwise.
   */
  function open() {
    if(!iframe && !window.localStorage.getItem(localStorageKey)){
      iframe = createIframe();
      document.body.append(iframe);
      return true;
    }

    return false;
  }

  function close() {
    window.localStorage.setItem(localStorageKey, 'true');
    iframe = undefined;
  }

  function createIframe(){
    const element = document.createElement('iframe');
    element.setAttribute('frameborder', '0');
    element.setAttribute('scrolling', 'no');
    element.setAttribute('src', src);
    element.className = 'banner-wrapper-iframe';

    element.addEventListener('close', close);
    element.addEventListener('tracking', onTracking);

    return element;
  }

  /**
   * @param {CustomEvent} event
   */
  function onTracking(event){
    if(event.detail && event.detail.action){
      tracking.track(event.detail);
    }
  }
};
